/* eslint-disable import/no-import-module-exports */
import 'normalize.css';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from '@zimpel/index';
import BugsnagClient from '@zimpel/common/helpers/bugsnagClient';

/* eslint-enable import/no-import-module-exports */

BugsnagClient.start();

const container = document.getElementById('root');
if (!container) {
  throw new Error('Root container not found!');
}
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

if (module.hot) {
  module.hot.accept();
}
