import React from 'react';
import styled, { keyframes } from 'styled-components';

/* stylelint-disable */
const frames = keyframes`
/* stylelint-enable */
  0% {
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;
  }

  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }

  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
`;

const SpinnerWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  width: 200px;
  height: 200px;
  background: none;
`;

const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  margin: 0;
  border-style: solid;
  border-width: 12px;
  border-radius: 50%;
  opacity: 0;
  animation: ${frames} 2s ease-out infinite;
  border-color: var(--color-primary-element);
`;

const LoadingIndicator: React.FC = () => (
  <SpinnerWrapper data-test="loading-spinner">
    <Spinner />
  </SpinnerWrapper>
);

export default LoadingIndicator;
