import isPlainObject from 'lodash/isPlainObject';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';

// This is copied from https://github.com/odynvolk/map-keys-deep-lodash
// We don't want this code as npm dependency as it would import the whole lodash file
// and it's not a lot of code so we can maintain it by our self.

const mapKeysDeep = (obj: any, cb: (value: any, key: string) => any, isRecursive?: boolean): any => {
  if (!obj && !isRecursive) {
    return {};
  }

  if (!isRecursive) {
    if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') {
      return {};
    }
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => mapKeysDeep(item, cb, true));
  }

  if (!isPlainObject(obj)) {
    return obj;
  }

  const result = mapKeys(obj, cb);

  return mapValues(result, (value) => mapKeysDeep(value, cb, true));
};

export default mapKeysDeep;
