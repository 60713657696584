import React, { Suspense, lazy } from 'react';

import styled from 'styled-components';

import { getConfig } from './common/config';
import './designTokens.css';
import './global.css';
import 'tippy.js/dist/tippy.css';
import LoadingIndicator from './common/components/LoadingIndicator';

const LoggedInApp = lazy(() => import('./LoggedInApp'));
const LoggedOutApp = lazy(() => import('./LoggedOutApp'));

const AppRoot = styled.div`
  font-family: var(--font-family-default);
  font-size: var(--font-size-default);
  line-height: var(--line-height-text);
  color: var(--color-text-default);
  background-color: var(--color-background-default);
`;

const App: React.FC = () => {
  const { accountId } = getConfig();

  return (
    <AppRoot>
      <Suspense fallback={<LoadingIndicator />}>
        {accountId ? <LoggedInApp /> : <LoggedOutApp />}
      </Suspense>
    </AppRoot>
  );
};

export default App;
export { AppRoot };
