import moment from 'moment';
import 'moment/locale/de';
import camelCase from 'lodash/camelCase';

import mapKeysDeep from '@zimpel/common/helpers/mapKeysDeep';

export type Theme = 'zimpel' | 'renteria' | 'mediadaten';
export type FeatureName = 'export' | 'mailing' | 'mailingApprove';

export const defaultLanguageCode = 'de';

export type CustomerLanguageCode = 'de' | 'en';
export type MailingLanguageCode = 'de' | 'en' | 'fr' | 'es' | 'it' | 'nl' | 'dk' | 'pt';
export type NewsroomLanguageCode = 'de' | 'en' | 'fr' | 'es' | 'it' | 'nl' | 'pt';

export const isCustomerLanguageCode = (language: string): language is CustomerLanguageCode => (
  ['de', 'en'].includes(language)
);

export const isMailingLanguageCode = (language: string): language is CustomerLanguageCode => (
  ['de', 'en', 'fr', 'es', 'it', 'nl', 'dk', 'pt'].includes(language)
);

type DeploymentTarget = 'local' | 'dev1' | 'dev2' | 'dev3' | 'dev4' | 'dev5' | 'dev6' | 'stage' | 'int';
type CustomerLocale = 'de-DE' | 'en-US';

export type Config = {
  accountId: number;
  customerId: number;
  username: string;
  locale: CustomerLocale;
  i18NLocale: string;
  language: CustomerLanguageCode;
  theme: Theme;
  customerRole: 'demo' | 'user' | 'admin',
  accountExpiresInDays?: number;
  deploymentTarget: DeploymentTarget | null;
  showDevTools: boolean;
  bugsnag?: {
    apiKey: string;
    stage: string;
  };
  flash: BackendNotification[];
  features: FeatureName[];
  summedMailingJoblistEntryLimit: number;
  appVersion: string;
  dpaIdSession: boolean;
  jobData: 'public_relations' | 'influencer' | 'advertiser'
}

type BackendNotification = {
  messageType: 'success' | 'error' | 'notice',
  message: string | string[],
}

let config: Config;

const setConfig = (newConfig: Partial<Config>): Config => {
  config = { ...config, ...newConfig };
  applyConfig(config);
  return config;
};

const getConfig = (): Config => {
  if (config) {
    return config;
  }

  setConfig(deepCamelizeKeys(getConfigFromBody()));
  applyConfig(config);

  return config;
};

const applyConfig = (config: Config) => {
  if (config.locale === 'en-US') {
    moment.locale('en');
    window.languageCode = 'en';
  } else {
    moment.locale('de');
    window.languageCode = 'de';
  }
};

const deepCamelizeKeys = (config: Config) => (
  mapKeysDeep(config, (value: unknown, key: string) => camelCase(key))
);

const getConfigFromBody = () => {
  const bodyElement = document.querySelector('body');
  if (!bodyElement) {
    throw new Error('document must contain a body element');
  }
  const { config } = bodyElement.dataset;

  if (!config) {
    throw new Error('data-config is missing on body element');
  }

  return JSON.parse(config);
};

export { getConfig, setConfig };
