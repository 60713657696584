import Bugsnag from '@bugsnag/js';
import { getConfig } from '@zimpel/common/config';

export default {
  start: (): void => {
    const config = getConfig();
    if (config.bugsnag === undefined) {
      // eslint-disable-next-line no-console
      console.warn('Bugsnag is not started as the api key or release stage is not configured.');
      return;
    }
    const { accountId, customerId } = config;
    const { apiKey, stage } = config.bugsnag;
    Bugsnag.start({
      apiKey,
      releaseStage: stage,
      collectUserIp: false,
      user: {
        id: accountId ? accountId.toString() : undefined,
      },
      metadata: {
        user: {
          customerId: customerId ? customerId.toString() : undefined,
        },
      },
      onError: (event) => {
        const error = event.errors[0];
        if (error.errorClass === 'BugsnagIgnoredError') {
          return false;
        }
        // Network errors are not useful in bugsnag
        if (error.errorClass === 'TypeError' && error.errorMessage === 'Failed to fetch') {
          return false;
        }

        return true;
      },
    });
  },
};
